export default {
    /* 状态 */
    
    // 状态
    stateFilter (val) {
      if (val === 0) {
          return '待维修'
      } else if (val === 1) {
          return '维修中'
      } else if (val === 2) {
          return '已维修'
      }
    },

    // 状态背景色
    stateFilterBg (val) {
      if (val === 0) {
          return { background: '#F30707' } 
      } else if (val === 1) {
          return { background: '#F30707' } 
      } else if (val === 2) {
          return { background: '#F30707' } 
      }
    },
}