import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/style/main.scss'
import SIdentify from '@/utils/SIdentify/SIdentify.vue'
import './common/font/font.css'
import filter from './filter/index'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import 'amfe-flexible/index.js'

import AMap from 'vue-amap'


// import 'lib-flexible/flexible'

Vue.config.productionTip = false
Vue.use(SIdentify)
Vue.use(ElementUI)
Vue.use(AMap)

// // 初始化vue-amap
AMap.initAMapApiLoader({
  // 高德key
  key: '16017152a3418aaa3824f2c1d723374d',
  // 插件集合 （插件按需引入）
  plugin: [
    'AMap.Geolocation',
    'AMap.Geocoder',
    'AMap.Autocomplete', // 输入提示插件
    'AMap.PlaceSearch', // POI搜索插件
    'AMap.Scale', // 右下角缩略图插件 比例尺
    'AMap.OverView', // 地图鹰眼插件
    'AMap.ToolBar', // 地图工具条
    'AMap.MapType', // 类别切换控件，实现默认图层与卫星图、实施交通图层之间切换的控制
    'AMap.PolyEditor', // 编辑 折线多，边形
    'AMap.CircleEditor', // 圆形编辑器插件
    'AMap.Geolocation', // 定位控件，用来获取和展示用户主机所在的经纬度位置
    'AMap.DragRoute',
    'AMap.CitySearch',
  ]
})

// 用于图片拼接路径
Vue.prototype.$baseUrl = window.API_ADRESS
Vue.prototype.$Amap = AMap

// 全局过滤器
Object.keys(filter).map(key => { Vue.filter(key, filter[key]) })

window.onunload = function () {
  localStorage.removeItem('ifm-code')
}

export let vue = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


