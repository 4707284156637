<template>
    <div>
        <h2>500</h2>
    </div>
</template>

<script>
export default {
   name:'Error'
}
</script>

<style scoped>

</style>