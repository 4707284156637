import Vue from 'vue'
import Vuex from 'vuex'
// import Login from '@/views/Login/store.js'
import * as api from './api.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
  },
  // localStorage.getItem('maintenance-state') ? JSON.parse(localStorage.getItem('maintenance-state')) :
  state: {
    
    /* 右边弹出框*/
    showPopup: false,
    funList: [  // 菜单列表
      {id: 3, name: '首页', show: '', showFlag: true, showFun: function(){ this.$router.push('/processing-center') },  popShow: false },
      {id: 1, name: '主题', show: 'SHOW_THEME_LIST', showFlag: true, showFun: () => {},  popShow: true },
      // {
      //   id: 2, 
      //   name: '注销', 
      //   showFlag: false, 
      //   showFun: function () {
      //     localStorage.removeItem('ifm-appToken')
      //     localStorage.removeItem('ifm-code')
      //     window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx6faf43eb8a00bacb&redirect_uri=http%3a%2f%2fh5property.enercomn.com%2f%23%2fmedium&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect'
      //   },
      //   popShow: false 
      // },
    ],

    /* 主题 */
    showThemeList: false, // 主题显示
    theme: {id: 1, name: '默认', themeData: 'default'}, // 当前主题
    themeList: [  // 主题列表
      {id: 1, name: '默认', themeData: 'default', color: '#1A3C76'},
      // {id: 2, name: '淡色', themeData: 'light', color: '#4c72b3'},
      // {id: 3, name: '黑色', themeData: 'black', color: '#000'},
    ],

    /* 报修单列表 */
    untreatedList: [],  // 待处理
    beingProcessedList: [], // 处理中
    processedList: [],  // 已处理

    
  },
  mutations: {
    /* 弹出框 */
    SHOW_POPUP(s, d) {
      s.showPopup = d
    },
    /* 主题 */
    SHOW_THEME_LIST (s, d) {
      s.showThemeList = d
    },
    THEME (s, d) {
      s.theme = d
    },

    /* 报修单列表 */
    UNTREATED_LIST (s, d) { // 未处理
      s.untreatedList = d
    },
    BEING_PROCESSED_LIST (s, d) { // 处理中
      s.beingProcessedList = d
    },
    PROCESSED_LIST (s, d) { // 已处理
      s.processedList = d
    },
    
  }
})
