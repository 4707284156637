<template>
  <div id="app" >
    <transition name="fade2">
      <router-view/>
    </transition>
  </div>
</template>
<script>
export default {
  mounted() {
    window.addEventListener('unload', this.saveState)
  },
  methods: {
    saveState() {
      if (this.$store) {
        localStorage.setItem("maintenance-state", JSON.stringify(this.$store.state));
      }
    },
  },
};
</script>
<style>

*{
  margin:0;
  padding:0;
}
  
li{
  list-style: none;
}
a{
  text-decoration: none;
}
</style>
