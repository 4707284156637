import axios from 'axios';
import NProgress from 'nprogress'
import { Loading, Message } from 'element-ui'

// import router from '../router'

axios.defaults.timeout = 60000

let l 

// 添加请求拦截器
axios.interceptors.request.use(function (config) {
    let { notLoading } = config
    // if (!notLoading) l = Loading.service()
    
    // 在发送请求之前做些什么;
    NProgress.start()
    return config;
  }, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  });

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
    // 对响应数据做点什么;

    if (l) l.close()
    NProgress.done()

    return response;
  }, function (error) {
    if (l) l.close()
    Message({message: '响应错误或超时请求', type: 'error'})
    // 对响应错误做点什么
    // if (error && error.response) {
    //   switch (error.response.status) {
    //     case 404:
    //       router.push({name:'404'});
    //       // error.message = '请求出错(404)'
    //       break;
        
    //     case 500:

    //       router.push({ name:'500'});
    //       //  error.message = '服务器错误(500)';
    //       break;
       
    //     default: error.message = `连接出错(${error.response.status})!`;
    //   }
    // }
  
    return Promise.reject(error);
  });
  

let baseUrl = window.API || process.env.VUE_APP_BASE_URL
// if (process.env.NODE_ENV == 'production' && API) baseUrl = API

export const GET=(url,params, o)=>{
  let {ShowFlag, notLoading} = o || {ShowFlag: false, notLoading: false}

  return axios.get(`${baseUrl}${url}`,{params:params}).then((data)=>{return data})
}

export const POST=(url,params,o)=>{
  let {ShowFlag, notLoading} = o || {ShowFlag: false, notLoading: false}

  return axios.post(`${baseUrl}${url}`,params,{headers:{appToken :localStorage.getItem('ifm-appToken'),projectId: localStorage.getItem('ifm-projectId'), requestUrl: window.location.hash.slice(1)}, notLoading})
  .then(( data )=>{ 
    Message.closeAll()
    if(data.data.resultCode == 200) { 
      return data 
    } else if (!ShowFlag){  Message({message: data.data.resultMessage, type: 'warning'}); return false}
  })
}

export const POSTFILE=(url,params)=>{
  return axios.post(`${baseUrl}${url}`,params,{headers:{appToken :localStorage.getItem('appToken'),projectId: localStorage.getItem('projectId'),'Content-Type': 'multipart/form-data'}})
  .then((data)=>{ return data})
}