import Vue from 'vue'
import VueRouter from 'vue-router'

// import Nonexistent from  '../views/404/404.vue'
import Error from  '../views/500/500.vue'
import { Message } from 'element-ui';


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/index',
  },
  {
    path: '/index',
    name: 'index',
    redirect: '/processing-center',
    component: () => import('../views/Index.vue'),
    children: [
      {
        path: '/processing-center',
        name: '处理中心',
        component: () => import('../views/processing-center/processing-center.vue'),
      },

      {
        path: '/processing-list/:type',
        name: '-b',
        component: () => import('../views/processing-list/processing-list.vue'),
      },

      {
        path: '/processing-detail/:creId',
        name: '报修详情信息-b',
        component: () => import('../views/processing-detail/processing-detail.vue'),
      },

      {
        path: '/maintenance',
        name: '添加报修-b',
        component: () => import('../views/maintenance/maintenance.vue'),
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

router.beforeEach((to, from, next) => {
  // to和from都是路由实例

  // to：即将跳转到的路由
  // from：现在的要离开的路由
  // next：函数

  /* 微信code */
  let code = getQueryString('code') // 拿到网址中的code

  if (code) localStorage.setItem('man-code', code)
  let localCode = localStorage.getItem('man-code')

  let env = process.env.NODE_ENV

  if (!localCode && env == 'production') window.location.href = window.WE_CHART_ADDRESS
  
  next()
  
})

/* 获取url - params */
function getQueryString(name) {
  let url = location.href

  let p = url.split('?')

  if (!p[1]) return  // 如果没有 ? 参数则直接返回
  
  let pArr = p[1].split('=')

  let idx

  pArr.map((t, i) => {
    if (t == name) {
      idx = i + 1
    }
  })

  return pArr[idx]

}
export default router
